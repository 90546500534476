import { named, withDependencies } from '@wix/thunderbolt-ioc'
import {
	BrowserWindow,
	BrowserWindowSymbol,
	IPropsStore,
	Props,
	PageFeatureConfigSymbol,
	ViewerModelSym,
	ViewerModel,
	SiteFeatureConfigSymbol,
} from '@wix/thunderbolt-symbols'
import { isSafari } from '@wix/thunderbolt-commons'
import { ITpaFullScreenMode, TpaPageConfig } from './types'
import { name as tpaCommonsName, TpaCommonsSiteConfig } from 'feature-tpa-commons'
import { name } from './symbols'
import { PERMITTED_FULL_SCREEN_TPAS_IN_MOBILE } from './utils/constants'
import { setFullScreenMode, removeFullScreenMode, hideSiteRoot } from './utils/tpaFullScreenUtils'

const fullScreenModeFactory = (
	{ widgetsClientSpecMapData, isMobileView }: TpaCommonsSiteConfig,
	{ widgets }: TpaPageConfig,
	{ deviceInfo }: ViewerModel,
	props: IPropsStore,
	window: BrowserWindow
): ITpaFullScreenMode => {
	const isComponentAllowedInFullScreenMode = (compId: string): boolean => {
		const widget: any = widgets[compId] || {}
		const { appDefinitionId } = widgetsClientSpecMapData[widget.widgetId] || {}
		return Object.values(PERMITTED_FULL_SCREEN_TPAS_IN_MOBILE).includes(appDefinitionId)
	}

	const enterFullScreenMode = (compId: string) => {
		setFullScreenMode(window)
		hideSiteRoot(window, true)

		props.update({
			[compId]: {
				// because any browser on iOS is safari underneath
				isSafari: isSafari(window!) || deviceInfo.os === 'IOS',
				isMobileFullScreenMode: true,
			},
			// TODO: remove. temporary solution until LAYOUT-385 is implemented
			STYLE_OVERRIDES_ID: {
				styles: {
					...props.get('STYLE_OVERRIDES_ID'),
					[`${compId}-pinned-layer`]: { 'z-index': 'var(--above-all-z-index) !important' },
				},
			},
		})
	}

	const exitFullScreenMode = (compId: string) => {
		removeFullScreenMode(window)
		hideSiteRoot(window, false)

		// TODO: remove. temporary solution until LAYOUT-385 is implemented
		const { styles: existingStyles } = props.get('STYLE_OVERRIDES_ID') || {}
		delete existingStyles[`${compId}-pinned-layer`]['z-index']

		props.update({
			[compId]: {
				isMobileFullScreenMode: false,
			},
			STYLE_OVERRIDES_ID: {
				styles: existingStyles,
			},
		})
	}

	return {
		setFullScreenMobile(compId: string, isFullScreen: boolean) {
			if (!isMobileView) {
				throw new Error('show full screen is only available in Mobile view')
			}

			if (isComponentAllowedInFullScreenMode(compId)) {
				if (isFullScreen) {
					enterFullScreenMode(compId)
				} else {
					exitFullScreenMode(compId)
				}
			}
		},
	}
}

export const TpaFullScreenMode = withDependencies(
	[
		named(SiteFeatureConfigSymbol, tpaCommonsName),
		named(PageFeatureConfigSymbol, name),
		ViewerModelSym,
		Props,
		BrowserWindowSymbol,
	],
	fullScreenModeFactory
)
