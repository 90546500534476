import { name } from '../symbols'
import { named, withDependencies } from '@wix/thunderbolt-ioc'
import { AppStyleProps, AppWidgetData, TpaMasterPageConfig, TpaPageConfig } from '../types'
import {
	IPropsStore,
	MasterPageFeatureConfigSymbol,
	PageFeatureConfigSymbol,
	Props,
	SiteFeatureConfigSymbol,
	TpaHandlerProvider,
	ViewerModel,
} from '@wix/thunderbolt-symbols'
import { CommonConfigSymbol, ICommonConfig } from 'feature-common-config'
import { name as tpaCommonsName, TpaCommonsSiteConfig } from 'feature-tpa-commons'

export type MessageData = { version: string }

export type AppIsAliveResponse = AppStyleProps &
	AppWidgetData & {
		fonts: { cssUrls: any; imageSpriteUrl: string; fontsMeta: any }
		commonConfig: ViewerModel['commonConfig']
		isVisualFocusEnabled: boolean
		siteColors: any
		siteTextPresets: any
	}

export const AppIsAliveHandler = withDependencies(
	[
		named(SiteFeatureConfigSymbol, tpaCommonsName),
		named(MasterPageFeatureConfigSymbol, name),
		named(PageFeatureConfigSymbol, name),
		CommonConfigSymbol,
		Props,
	],
	(
		{ wixStaticFontsLinks, imageSpriteUrl }: TpaCommonsSiteConfig,
		{ fontsMeta, siteColors, isVisualFocusEnabled, siteTextPresets }: TpaMasterPageConfig,
		{ widgets }: TpaPageConfig,
		commonConfigAPI: ICommonConfig,
		props: IPropsStore
	): TpaHandlerProvider => ({
		getTpaHandlers() {
			return {
				appIsAlive(compId: string, msgData, { originCompId }): AppIsAliveResponse {
					props.update({
						[compId]: {
							sentAppIsAlive: true,
						},
					})
					const widgetData = widgets[originCompId] || {
						style: {
							colors: {},
							numbers: {},
							booleans: {},
							fonts: {},
							googleFontsCssUrl: '',
							uploadFontFaces: '',
						},
					}

					return {
						fonts: {
							cssUrls: wixStaticFontsLinks,
							imageSpriteUrl,
							fontsMeta,
						},
						commonConfig: commonConfigAPI.getCommonConfig(),
						isVisualFocusEnabled,
						siteColors,
						siteTextPresets,
						...widgetData,
					}
				},
			}
		},
	})
)
